<script lang="jsx">
import ColorV2Radio from './ColorV2Radio.vue'
import ColorV2Desc from './ColorV2Desc.vue'
import ColorV2PromotionText from './ColorV2PromotionText.vue'
import ColorV2LargeImage from './ColorV2LargeImage.vue'
import ColorV2Shade from './ColorV2Shade.vue'
import { PromotionUtils } from 'public/src/pages/common/promotion/utils'

export default {
  name: 'ColorV2',
  functional: true,
  components: {
    ColorV2Radio,
    ColorV2Desc,
    ColorV2PromotionText
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    lazyImg: {
      type: String,
      default: ''
    },
    colorConf: {
      type: Object,
      default: () => ({})
    },
    detail: {
      type: Object,
      default: () => ({})
    },
    goodsDesc: {
      type: String,
      default: ''
    },
    eventCategory: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    },
    switchColor: {
      type: Function,
      default: () => {}
    },
    colorType: {
      type: String,
      default: ''
    },
    colorsInfo: {
      type: Array,
      default: () => []
    },
    reviewLocation: {
      type: String,
      default: ''
    },
    promotionTagInfos: {
      type: Object,
      default: () => ({})
    },
    labelExpose: {
      type: Function,
      default: () => {}
    },
    largeImageMode: {
      type: Boolean,
      default: false
    },
    supportLargeImageList: {
      type: Boolean,
      default: false
    },
    goodsDescState: {
      type: Object,
      default: () => {}
    },
    goodsMainAttPicInfo: {
      type: Object,
      default: () => {}
    },
    showGoodsDesc: {
      type: Boolean,
      default: false
    },
    isRw: {
      type: Boolean,
      default: false
    },
    publicCdn: {
      type: String,
      default: ''
    },
    loadConf: {
      type: Object,
      default: () => ({
        size: '750x',
        num: 1
      })
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    /**
     * 加载状态
     */
    loading: {
      type: Boolean
    },
    /**
     * 最后点击的色块
     */
    lastClickColor: {
      type: String,
      default: ''
    },
    showFindMyShadeEntry: {
      type: Boolean,
      default: false
    },
    clickShowFindMyShade: {
      type: Function,
      default: () => {},
    },
    analysisInfo: {
      type: Object,
      default: () => ({})
    },
    isSkcPrepose: {
      type: Boolean,
      default: false
    },
    isShowAttrNewIcon: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props }) {
    const {
      language,
      lazyImg,
      colorConf,
      detail,
      goodsDesc,
      eventCategory,
      from,
      switchColor,
      colorType,
      colorsInfo,
      reviewLocation,
      promotionTagInfos,
      labelExpose,
      largeImageMode,
      supportLargeImageList,
      showGoodsDesc,
      isRw,
      publicCdn,
      loadConf,
      fixedRatio,
      isSupportCropImage,
      loading,
      lastClickColor,
      goodsMainAttPicInfo,
      showFindMyShadeEntry,
      clickShowFindMyShade,
      analysisInfo,
      isSkcPrepose,
      isShowAttrNewIcon,
    } = props

    const commonColorListShow = !supportLargeImageList ? true : !largeImageMode
    const formatGoodsDesc =
      typeof goodsDesc !== 'string' || !goodsDesc
        ? ''
        : goodsDesc.replace(/\n/g, '<br>')
    const getSelectedColor = () => {
      const { colorsInfo, detail } = props
      if (!Array.isArray(colorsInfo) || !colorsInfo.length) {
        return null
      }
      return colorsInfo.find(item => item.goods_id == detail.goods_id) || null
    }

    const selectedColor = getSelectedColor()

    const getPromotionText = () => {
      const goods_id = selectedColor && selectedColor.goods_id
      const promotionTag = promotionTagInfos && promotionTagInfos[goods_id]
      if (!promotionTagInfos[goods_id]?.showVisible) return ''
      if (!(promotionTag && promotionTag.tagType === 2)) return ''
      return promotionInfoTextSel(promotionTag)
    }

    const promotionInfoTextSel = item => {
      const { language } = props
      const { flashType } = item || {}
      const filterID = [10, 11]
      const isLabel = filterID.includes(Number(item.typeId))
      if (item.typeId == 10 && [2, 3].includes(flashType)) {
        return flashType == 2
          ? language.SHEIN_KEY_PWA_21931
          : language.SHEIN_KEY_PWA_21932
      } else if (isLabel) {
        return item.lable
      }
      return item?.tips?.text
    }

    const getCurrentIndex = () => {
      return colorsInfo?.findIndex(
        item => item.goods_id === selectedColor?.goods_id
      )
    }

    const promotionLink = () => {
      PromotionUtils.jumpToAdd(
        promotionTagInfos[selectedColor.goods_id],
        'goodsDetail'
      )
    }

    const promotionEntryExpose = () => {
      const goods_id = selectedColor && selectedColor.goods_id
      const promotionInfo = promotionTagInfos?.[goods_id]
      const { id, typeId } = promotionInfo || {}
      return {
        id: '1-6-1-125',
        once: true,
        data: {
          src_module: 'promotion',
          src_identifier: `on=${typeId}\`cn=${id}\`ps=${
            currentIndex + 1
          }_1\`jc=promotion_${id}`
        }
      }
    }

    const promotionEntryClick = () => {
      const goods_id = selectedColor && selectedColor.goods_id
      const promotionInfo = promotionTagInfos?.[goods_id]
      const { id, typeId } = promotionInfo || {}
      return {
        id: '1-6-1-126',
        data: {
          src_module: 'promotion',
          src_identifier: `on=${typeId}\`cn=${id}\`ps=${
            currentIndex + 1
          }_1\`jc=promotion_${id}`
        }
      }
    }

    const promotionText = getPromotionText()
    const currentIndex = getCurrentIndex()

    return (
      <div class="goods-color__list-box">
        {/* 色块部分 */}
        {commonColorListShow ? (
          <ColorV2Radio
            colorType={colorType}
            colorsInfo={colorsInfo}
            detail={detail}
            eventCategory={eventCategory}
            reviewLocation={reviewLocation}
            from={from}
            colorConf={colorConf}
            promotionTagInfos={promotionTagInfos}
            labelExpose={labelExpose}
            switchColor={switchColor}
            lazyImg={lazyImg}
            loadConf={loadConf}
            fixedRatio={fixedRatio}
            isSupportCropImage={isSupportCropImage}
            loading={loading}
            lastClickColor={lastClickColor}
            supportLargeImageList={supportLargeImageList}
            isSkcPrepose={isSkcPrepose}
            analysisInfo={analysisInfo}
            isShowAttrNewIcon={isShowAttrNewIcon}
          />
        ) : null}
        {/* 大图模式颜色选框 */}
        {supportLargeImageList ? (
          <ColorV2LargeImage
            showList={largeImageMode}
            list={colorsInfo}
            eventCategory={eventCategory}
            reviewLocation={reviewLocation}
            from={from}
            colorConf={colorConf}
            promotionTagInfos={promotionTagInfos}
            selectedColor={detail && detail.goods_id}
            lazyImg={lazyImg}
            isRw={isRw}
            publicCDN={publicCdn}
            switchColor={switchColor}
            fixedRatio={fixedRatio}
            isSupportCropImage={isSupportCropImage}
            isSkcPrepose={isSkcPrepose}
            analysisInfo={analysisInfo}
            isShowAttrNewIcon={isShowAttrNewIcon}
          />
        ) : null}
        {/* 加车弹框中 promotionText */}
        <ColorV2PromotionText
          supportLargeImageList={supportLargeImageList}
          largeImageMode={largeImageMode}
          promotionText={promotionText}
          showGoodsDesc={showGoodsDesc}
          promotionLink={promotionLink}
          promotionEntryExpose={promotionEntryExpose}
          promotionEntryClick={promotionEntryClick}
        />
        {/* 颜色描述 */}
        {!isSkcPrepose && (
          <ColorV2Desc
            goodsDesc={formatGoodsDesc}
            language={language}
            goodsMainAttPicInfo={goodsMainAttPicInfo}
            maxRetry={30}
            from={from}
            goodsId={detail.goods_id}
          />
        )}
        {!!showFindMyShadeEntry && <ColorV2Shade language={language} clickShowFindMyShade={clickShowFindMyShade} />}
      </div>
    )
  }
}
</script>

<style lang="less">
.goods-color {
  color: #222;
  &__wrap {
    a {
      color: inherit;
      text-decoration: unset;
    }
    li {
      position: relative;
    }
  }
  &__imgs {
    padding: 0.15rem 0 0.3733rem;
    font-size: 0;
    .flexbox();
    flex-flow: row nowrap;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__img-item {
    position: relative;
    flex: 0 0 1.12rem;
    width: 1.12rem;
    border: 2px solid transparent;
    .margin-r(0.32rem);
    &.color-active {
      border: 1px solid #222;
      padding: 0.053333rem;
      /*rw:begin*/
      border: 1px solid #ff696e;
      .goods-color__soldout {
        i {
          transform: translate3d(0, 0, 0) rotate(-53.7deg) scale(0.5);
        }
      }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0 /*rtl:ignore*/;
        bottom: 0;
        right: 0 /*rtl:ignore*/;
        border: 1px solid rgba(34, 34, 34, 0.08);
      }
    }
    .goods-color__soldout {
      i {
        width: 3.8rem;
        transform: translate3d(0, 0, 0) rotate(-52.8deg) scale(0.5);
      }
    }
    .goods-color__new{
      right: -0.37333rem;
    }
  }
  &__text {
    margin-bottom: 0.1067rem;
    margin-top: 0.15rem;
    min-height: 0.96rem;
    .color-active {
      .border-dpr(border, 2px, #222);
      color: #222;
      /* rw:begin */
      .border-dpr(border, 2px, #ff696e);
      color: #ff696e;
    }
  }
  &__text-item {
    display: inline-block;
    max-width: 100%;
    padding: 0.16rem 0.333333rem 0.1333rem 0.333333rem;
    .margin-r(0.32rem);
    margin-bottom: 0.213333rem;
    .border-dpr(border, 2px, #e5e5e5);
    border-radius: 1.28rem;
    line-height: 0.4533rem;
    .font-dpr(28px);
  }
  &__prepose {
    margin-top: 0.266rem;
    .flexbox();
    flex-flow: row nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    &.no-right-arrow {
      padding-right: 0;
    }
  }
  &__prepose-item {
    display: flex;
    align-items: center;
    .margin-r(0.16rem);
    .font-dpr(24px);
    border-radius: 2px;
    color: #000;
    .goods-color__hot {
      .right(-0.046rem);
    }
    .goods-color__soldout {
      i {
        width: 3.8rem;
      }
    }
    &.color-active {
      border: 1px solid #000;
      padding: 0 0.213rem 0 0.106rem;
      .skc-prepose-container__img {
        width: 0.853rem;
        height: 0.853rem;
        border-radius: 1px;
        margin-right: 0.213rem;
      }
      .skc-prepose-container__text {
        display: inline;
      }
    }
  }
  &__list {
    margin-top: 0.15rem;
    margin-bottom: 0.0533rem;
    font-size: 0;
    .flexbox();
    flex-flow: row wrap;
  }
  &__line {
    flex-flow: row nowrap;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    .margin-r(0.32rem);
    margin-bottom: 0.32rem;
    width: 26px;
    height: 26px;
    padding: 1px;

    &.active {
      position: relative;
      padding: 3px;
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid #222222;
        border-radius: 100%;
      }
    }

    &-inner {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(34, 34, 34, 0.06);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;

      &.soldout {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0) rotate(-45deg);
          transform-origin: center;
          width: 100%;
          height: 2px;
          background: rgba(153, 153, 153, 0.8);
          z-index: 1;
        }
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0) rotate(-45deg);
          transform-origin: center;
          width: 100%;
          height: 4px;
          background: rgba(255, 255, 255, 0.9);
          font-size: 0;
        }
      }
    }
  }
  &__low {
    position: absolute;
    .right(-.1867rem);
    top: -0.08rem;
    width: .48rem;
    height: .27rem;
  }
  &__low-img&__low-img {
    .right(-.13rem)
  }
  &__hot {
    position: absolute;
    .right(-0.32rem);
    top: -0.08rem;
    width: 0.6667rem;
    height: 0.2667rem;
  }
  &__new {
    position: absolute;
    .right(-0.4266rem);
    top: -0.08rem;
    width: 0.72rem;
    height: 0.2667rem;
  }
  &__evolu {
    position: absolute;
    .right(-0.16rem);
    top: -0.08rem;
    width: 0.4533rem;
    height: 0.2667rem;
  }
  &__soldout {
    position: absolute;
    top: 0;
    left: 0 /*rtl:ignore*/;
    right: 0 /*rtl:ignore*/;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.6);
    i {
      width: 1.84rem;
      height: 8px;
      position: absolute;
      transform-origin: left center;
      transform: translate3d(0%, 0, 0) rotate(-45deg) scale(0.5);
      background: rgba(153, 153, 153, 0.6);
      bottom: -4px;
      left: 0 /*rtl:ignore*/; // 不要用.left()
      [data-dpr='1'] & {
        height: 4px;
        bottom: -2px;
      }
      [data-dpr='3'] & {
        height: 12px;
        bottom: -6px;
      }
      &::before {
        position: absolute;
        top: -4px;
        content: '';
        width: 100%;
        display: block;
        border: 2px solid rgba(255, 255, 255, 0.6);
        [data-dpr='1'] & {
          top: -2px;
        }
        [data-dpr='3'] & {
          top: -6px;
        }
      }
      &::after {
        position: absolute;
        bottom: -4px;
        content: '';
        width: 100%;
        display: block;
        border: 2px solid rgba(255, 255, 255, 0.6);
        [data-dpr='1'] & {
          bottom: -2px;
        }
        [data-dpr='3'] & {
          bottom: -6px;
        }
      }
    }
  }
  &__tag-discount {
    position: absolute;
    top: 0;
    left: 100%;
    transform-origin: 0 0;
    transform: scale(0.5) translate(-50%, -50%);
    border: 0.042rem solid rgba(255, 255, 255, 0.86);
    border-radius: 0.1rem;
    padding: 0.1rem;
    overflow: hidden;
    font-size: 0.42rem;
    line-height: 1.25;
    background: @sui_color_promo_bg;
    color: @sui_color_promo_dark;
    white-space: nowrap;
  }
  &__tag-promotion {
    position: absolute;
    top: 0;
    left: 100%;
    transform-origin: 0 0;
    transform: translate(-50%, -50%);
    border: 0.021rem solid rgba(255, 255, 255, 0.86);
    border-radius: 0.053rem;
    padding: 0.026rem 0.026rem 0.026rem 0.053rem;
    overflow: hidden;
    font-size: 0.32rem;
    line-height: 1;
    background: @sui_color_promo_bg;
    color: @sui_color_promo_dark;
  }
  &__promotion-link {
    margin: -0.11rem 0 0.64rem;
    &_large {
      margin-top: 0.213rem;
    }
    &_desc {
      margin-bottom: 0.213rem;
    }
  }
  &__promotion-link-box {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: #c44a01;
    overflow: hidden;
  }
  &__promotion-link-text {
    margin: 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.color-active {
  .skc-prepose-container__text.two-line {
    .line-camp(2);
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
